// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jQuery from "jquery";
require("bootstrap");
require("tempusdominus-bootstrap-4");
require("moment/locale/ja");
import Rails from '@rails/ujs';
Rails.start();
require("turbolinks").start();
require("select2");
require("@rails/activestorage").start();
require("channels");
import BugsnagPerformance from '@bugsnag/browser-performance'
import AOS from 'aos';
import Swiper from 'swiper';
import { A11y, Autoplay, Pagination } from 'swiper/modules';

// Fonts
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

// stylesheets
require("../stylesheets/application.scss");

import "select2";


jQuery(document).on("ready turbolinks:load", function () {
  jQuery(".customers-dropdown").select2();

  if (document.querySelectorAll('.swiper').length > 0) {
    const swiper = new Swiper('.swiper', {
      // configure Swiper to use modules
      modules: [A11y, Autoplay, Pagination],
      a11y: true,
      autoplay: {
        enable: true,
        pauseOnMouseEnter: true,
      },
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      pagination: {
        enable: true,
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        980: {
          slidesPerView: 2
        }
      },
    });
  }

});

jQuery(function () {
  jQuery("select").on("change", function (event) {
    jQuery("#extra_price").html(
      formatCurrency(jQuery("#extra_" + jQuery(".select").val()).html())
    );
    computetotal(event);
  });

  jQuery("#inlineDatepicker").datetimepicker({
    inline: true,
    sideBySide: false,
    format: "L",
    useCurrent: false,
    defaultDate: jQuery("#weekStardate").val(),
  });

  jQuery("#inlineDatepicker").on("change.datetimepicker", ({ date, oldDate }) => {
    var fdate = moment(date);
    jQuery("#weekStardate").val(fdate.format("D/M/YYYY"));
  });

  jQuery("#inlineDatepicker1").datetimepicker({
    inline: true,
    sideBySide: false,
    format: "L",
    useCurrent: false,
    defaultDate: jQuery("#holidayStartdate").val(),
  });

  jQuery("#inlineDatepicker1").on("change.datetimepicker", ({ date, oldDate }) => {
    var fdate = moment(date);
    jQuery("#holidayStartdate").val(fdate.format("D/M/YYYY"));
  });

  jQuery("#inlineDatepicker2").datetimepicker({
    inline: true,
    sideBySide: false,
    format: "L",
    useCurrent: false,
    defaultDate: jQuery("#holidayCutoffdate").val(),
  });

  jQuery("#inlineDatepicker2").on("change.datetimepicker", ({ date, oldDate }) => {
    var fdate = moment(date);
    jQuery("#holidayCutoffdate").val(fdate.format("D/M/YYYY"));
  });

  jQuery("#inlineDatepicker3").datetimepicker({
    inline: true,
    sideBySide: false,
    format: "L",
    useCurrent: false,
    defaultDate: jQuery("#holidayDeliverydate").val(),
  });

  jQuery("#inlineDatepicker3").on("change.datetimepicker", ({ date, oldDate }) => {
    var fdate = moment(date);
    jQuery("#holidayDeliverydate").val(fdate.format("D/M/YYYY"));
  });
});

jQuery(document).on("scroll", function (e) {
  var scrollTop = jQuery(document).scrollTop();
  if (scrollTop > 50) {
    jQuery(".main-header").addClass("sticky");
    jQuery(".logo").css("width", "50%");
    jQuery(".nav-link").css("line-height", "3");
  } else {
    jQuery(".main-header").removeClass("sticky");
    jQuery(".logo").css("width", "65%");
    jQuery(".nav-link").css("line-height", "5");
  }
});

jQuery(document).on('load', function() {
  AOS.init({
    duration: 1500
  });
})

BugsnagPerformance.start({
  apiKey: 'ccf46114d1a50b9f43cb8eef43699b4e',
  releaseStage: process.env.RAILS_ENV
})
